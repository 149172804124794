/** @jsx jsx */
import { jsx } from "@emotion/core";
import { Fragment } from "react";
import { Button, ErrorMessage } from "../../../components";
import { CartSummary } from "../cartSummary/summary";

export function Shipping({ cart, form, saveShipping, post }) {
  const model = form.model;
  const render = (name) => form.renderControl(name);

  const val = form.getValue();
  const billingAddress = model.find("billingAddress");
  const shippingAddress = model.find("shippingAddress");

  const next = (e) => {
    form.validateForm(e, () => {
      saveShipping();
    });
  };

  if (billingAddress) {
    billingAddress.disableValidators = val.useSameAddress;
  }

  shippingAddress.disableValidators = false;

  if (val.shippingMethod === "Pickup" || val.shippingMethod === "0") {
    shippingAddress.disableValidators = true;
  }

  return (
    <div>
      <div className='row'>
        <div className='col-md-9'>
          <form>
            <h3>Delivery Method</h3>
            {render("shippingMethod")}
            {val.shippingMethod !== "" && (
              <Fragment>
                {val.shippingMethod === "Standard" && (
                  <Fragment>
                    <h3> Delivery Date & Time</h3>
                    <div className='row'>
                      <div className='col-12 col-md-6'>
                        {render("deliveryPickupDate")}
                      </div>
                      <div className='col-12 col-md-6'>
                        {render("deliveryPickupTime")}
                      </div>
                    </div>

                    <h3> Enter Delivery Instructions</h3>
                    {render("deliveryInstructions")}

                    <h3>Shipping Address</h3>
                    <Address form={form} addressModel={shippingAddress} />
                  </Fragment>
                )}

                {val.shippingMethod === "Pickup" && (
                  <Fragment>
                    <h3> Pickup Date & Location </h3>

                    <div className='row'>
                      <div className='col-12 col-md-6'>
                        {render("deliveryPickupDate")}
                      </div>
                      <div className='col-12 col-md-6'>
                        {render("location")}
                      </div>
                    </div>
                  </Fragment>
                )}

                <h3>Customer Information</h3>
                {!val.useSameAddress && (
                  <Address form={form} addressModel={billingAddress} />
                )}
              </Fragment>
            )}
          </form>
        </div>

        <div className='col-md-3'>
          <CartSummary cart={cart} />
        </div>
      </div>

      {val.shippingMethod !== "" && (
        <Fragment>
          <div className='form__actions'>
            <Button onClick={next} status={post.status}>
              {/* Next new change for speciall  */}
              Apply
            </Button>
            <ErrorMessage
              errors={form.errors}
              summaryMessage='Please review the errors.'
            />
            <ErrorMessage errors={post.errors} />
          </div>
        </Fragment>
      )}
    </div>
  );
}

function Address({ form, addressModel }) {
  const render = (name) => form.renderControl(name, addressModel);
  return (
    <div>
      <div className='row'>
        <div className='col-12'>{render("contactName")}</div>
      </div>
      <div className='row'>
        <div className='col-12'>{render("stationName")}</div>
      </div>
      <div className='row'>
        <div className='col-12'>{render("department")}</div>
      </div>
      <div className='row'>
        <div className='col-12 col-md-6'>{render("email")}</div>
        <div className='col-12 col-md-6'>{render("mobile")}</div>
      </div>
    </div>
  );
}
