import { css } from '@emotion/core';
import { variables, mq } from '../../../cssInJs';

//const numberOfTiles = [1, 1, 1, 1]
//const marginLR = [14, 14, 14, 14]
const marginTB = [14, 14, 14, 14]
//const widthPercent = (index) => 100 / numberOfTiles[index] + '%';

const linkText = {
  color: variables.fontColor,
  //fontSize: '0.8rem',
  //padding: '0 20px',
  '&:hover': {
    color: variables.fontColor,
  }
}

export const tileListStyle = {
  tiles: mq({
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    //marginLeft: [-marginLR[0] / 2, -marginLR[1] / 2, -marginLR[2] / 2, -marginLR[3] / 2],
    //marginRight: [-marginLR[0] / 2, -marginLR[1] / 2, -marginLR[2] / 2, -marginLR[3] / 2],
    //marginTop: [marginTB[0] / 2, marginTB[1] / 2, marginTB[2] / 2, marginTB[3] / 2],
    marginBottom: [marginTB[0] / 2, marginTB[1] / 2, marginTB[2] / 2, marginTB[3] / 2],
    maxWidth:['980px'],
    margin: 'auto',
    marginLeft: ['-10px', null, 'auto'],
    marginRight: ['-10px', null, 'auto'],
    marginTop : '-1rem'
  }),
  categoryList : mq({
    marginTop: [marginTB[0] / 2, marginTB[1] / 2, marginTB[2] / 2, marginTB[3] / 2],
    marginBottom: [marginTB[0] / 2, marginTB[1] / 2, marginTB[2] / 2, marginTB[3] / 2],
    maxWidth:['960px'],
    margin: 'auto',
    //marginLeft: ['-10px', null, 'auto'],
   // marginRight: ['-10px', null, 'auto'],
    'div.ff.form-group' : {
      maxWidth: '250px'
    }
  })
}

export const tileStyle = {
  tile: mq({
    //paddingLeft: [marginLR[0] / 2, marginLR[1] / 2, marginLR[2] / 2, marginLR[3] / 2],
    //paddingRight: [marginLR[0] / 2, marginLR[1] / 2, marginLR[2] / 2, marginLR[3] / 2],
    paddingLeft : '10px',
    paddingRight : '10px',
    
    //paddingTop: [marginTB[0] / 2, marginTB[1] / 2, marginTB[2] / 2, marginTB[3] / 2],
    //paddingBottom: [marginTB[0] / 2, marginTB[1] / 2, marginTB[2] / 2, marginTB[3] / 2],

    paddingTop : '1rem',
    paddingBottom: '1rem',
    
    //maxWidth: [widthPercent(0), widthPercent(1), widthPercent(2), widthPercent(3)],
    //flexBasis: [widthPercent(0), widthPercent(1), widthPercent(2), widthPercent(3)],

    maxWidth : ['100%', null, '80%'],
    flexBasis : ['100%', null, '80%'],
    
    flexGrow: 0,
    flexShrink: 0
  }),
  tileLink: {
    background: 'transparent',
    height: '100%',
    display: 'flex',
    //flexDirection: 'column',
    border:'1px solid #8b8b8b',
    borderRadius: '12px',
    padding:'16px',
    backgroundColor:'white',
    //padding: 5,
    //border: '1px solid red',
    '&:hover': {
      textDecoration: 'none'
      //background: '#f3f3f3'
    }
  },
  prdImage: mq({
    //padding: '0 5px',
    //maxWidth: '190px',
    //margin: 'auto',
    //width: '100%',
    //backgroundColor: 'grey',
    flexBasis: ['40%', null, '24%'],
    maxWidth: ['40%', null, '24%'],
  }),
  bg: {
    //height: 0,
    //paddingBottom: '100%',
    paddingTop: '110px',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundColor: 'white',
    borderRadius: '12px',
    border:'1px solid #8b8b8b'
  },
  desContainer : css(mq({
    //marginTop: '0.5rem',
    //backgroundColor:'#ebebeb',
    padding:'0.5rem',
    flexBasis: ['60%', null, '100%'],
    maxWidth:['60%', null, '100%'],
    paddingTop:'0px',
    paddingLeft:'0.8rem'
  })),
  shortDes : css({
    //textAlign:'center',
    lineHeight:'1.3',
    fontSize:'0.8rem',
    //marginBottom:'8px',
    color:variables.fontColor
  }),
  name: css(linkText, {
    fontWeight: 'bold',
    fontSize:'1rem',
    fontFamily: variables.familyHeader,
    //textAlign:'center',
    //marginBottom:'8px',
    color:variables.goldColor
  }),
  price: css(linkText, {
    //marginTop: 'auto',
    //textAlign:'center',
    //marginBottom:'1rem'
    fontWeight:600,
    fontSize:'0.7rem',
    marginTop:'0.5rem'
    //marginBottom:'0.5rem'
  }),
  button : css({
    textAlign:'center',
    width:'100%',
    color:variables.fontColor,
    fontWeight:'600',
    fontSize:'0.8rem',
    border:'1px solid',
    borderColor:variables.goldColor
  })
}