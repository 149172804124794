import { fb, validators } from "../../../form";

export function getShippingFormData(cart) {
  const data = cart || {};
  const shippingMethods = [{ name: "Pickup", value: "Pickup" }];
  const shippingCountry = [
    { name: "Australia", value: "Australia" },
    { name: "International", value: "International" },
  ];
  let locationList = [
    { name: "Drakes Aberfoyle Park", value: "Drakes Aberfoyle Park" },
    { name: "Drakes Aldinga", value: "Drakes Aldinga" },
    { name: "Drakes Allenby Gardens", value: "Drakes Allenby Gardens" },
    { name: "Drakes Angle Vale", value: "Drakes Angle Vale" },
    { name: "Drakes Ascot Park", value: "Drakes Ascot Park" },
    { name: "Drakes Aston Hills", value: "Drakes Aston Hills" },
    { name: "Drakes Blackwood", value: "Drakes Blackwood" },
    { name: "Drakes Castle Plaza", value: "Drakes Castle Plaza" },
    { name: "Drakes Clovercrest", value: "Drakes Clovercrest" },
    { name: "Drakes Collinswood", value: "Drakes Collinswood" },
    { name: "Drakes Elizabeth Park", value: "Drakes Elizabeth Park" },
    { name: "Drakes Eyre", value: "Drakes Eyre" },
    { name: "Drakes Findon", value: "Drakes Findon" },
    { name: "Drakes Fulham Gardens", value: "Drakes Fulham Gardens" },
    { name: "Drakes Gawler", value: "Drakes Gawler" },
    { name: "Drakes Gawler East", value: "Drakes Gawler East" },
    {
      name: "Drakes Glenunga Mini /Glen Osmond",
      value: "Drakes Glenunga Mini /Glen Osmond",
    },
    { name: "Drakes Golden Grove", value: "Drakes Golden Grove" },
    { name: "Drakes Grange mini", value: "Drakes Grange mini" },
    { name: "Drakes Hackham", value: "Drakes Hackham" },
    { name: "Drakes Hallett Cove", value: "Drakes Hallett Cove" },
    { name: "Drakes Hope valley", value: "Drakes Hope valley" },
    { name: "Drakes Murray Bridge", value: "Drakes Murray Bridge" },
    { name: "Drakes Newton", value: "Drakes Newton" },
    { name: "Drakes North Haven", value: "Drakes North Haven" },
    { name: "Drakes Royal Park", value: "Drakes Royal Park" },
    { name: "Drakes Salisbury North", value: "Drakes Salisbury North" },
    { name: "Drakes Seaford", value: "Drakes Seaford" },
    { name: "Drakes Semaphore", value: "Drakes Semaphore" },
    { name: "Drakes Sunnybrook Mini", value: "Drakes Sunnybrook Mini" },
    { name: "Drakes Surrey Downs", value: "Drakes Surrey Downs" },
    { name: "Drakes Torrensville", value: "Drakes Torrensville" },
    { name: "Drakes Victor Harbor", value: "Drakes Victor Harbor" },
    {
      name: "Drakes Walkley Heights Mini",
      value: "Drakes Walkley Heights Mini",
    },
    { name: "Drakes Wayville", value: "Drakes Wayville" },
    { name: "Drakes West Beach Mini", value: "Drakes West Beach Mini" },
    { name: "Drakes Woodcroft", value: "Drakes Woodcroft" },
    { name: "Drakes Yankalilla", value: "Drakes Yankalilla" },
    { name: "Drakes Kingscote", value: "Drakes Kingscote" },
    { name: "Drakes Port Lincoln", value: "Drakes Port Lincoln" },
    { name: "Drakes Ardrossan", value: "Drakes Ardrossan" },
    { name: "Drakes Wallaroo", value: "Drakes Wallaroo" },
    { name: "Drakes Moonta", value: "Drakes Moonta" },
  ];
  locationList = [{ name: "Select Location", value: "" }, ...locationList];

  let pickupDateList = [
    {
      name: "Monday, September 23rd 2024",
      value: "Monday, September 23rd 2024",
    },
    {
      name: "Tuesday, September 24th 2024",
      value: "Tuesday, September 24th 2024",
    },
    {
      name: "Wednesday, September 25th 2024",
      value: "Wednesday, September 25th 2024",
    },
    {
      name: "Thursday, September 26th 2024",
      value: "Thursday, September 26th 2024",
    },
    {
      name: "Friday, September 27th 2024",
      value: "Friday, September 27th 2024",
    },
    {
      name: "Saturday, September 28th 2024",
      value: "Saturday, September 28th 2024",
    },
  ];
  pickupDateList = [{ name: "Select Time", value: "" }, ...pickupDateList];

  if (!localStorage.dumyDeliveryDate) {
    localStorage.setItem("dumyDeliveryDate", "");
  }

  const model = fb.group({
    billingAddress: address(data.billingAddress),
    shippingAddress: address(data.shippingAddress),
    shippingMethod: [
      data.shippingMethod || "",
      [],
      {
        label: null,
        type: "radioList",
        options: shippingMethods,
        style: { inline: true },
      },
    ],
    shippingCountry: [
      data.shippingCountry || "Australia",
      [],
      {
        label: null,
        type: "radioList",
        options: shippingCountry,
        style: { inline: true },
      },
    ],
    useSameAddress: [
      data.useSameAddress || false,
      [],
      { label: `Use the same address`, type: "checkbox" },
    ],
    deliveryPickupDate: [
      data.deliveryPickupDate || "",
      [validators.Required()],
      { label: null, type: "select", options: pickupDateList },
    ],
    location: [
      data.location || "",
      [validators.Required()],
      { label: null, type: "select", options: locationList },
    ],
    deliveryInstructions: [
      data.deliveryInstructions || "",
      [],
      { label: null, type: "textarea" },
    ],
  });
  return model;
}

export function address(model) {
  const data = model || {};
  return fb.group({
    contactName: [
      data.contactName || "",
      [validators.Required()],
      { label: "Contact Name", type: "text" },
    ],
    stationName: [
      data.stationName || "",
      [validators.Required()],
      { label: "Station / Business Name", type: "text" },
    ],
    department: [
      data.department || "",
      [],
      { label: "Department", type: "text" },
    ],
    mobile: [
      data.mobile || "",
      [validators.Required()],
      { label: "Contact Number", type: "tel" },
    ],
    email: [
      data.email || "",
      [validators.Required(), validators.Email()],
      { label: "Email Address", type: "email" },
    ],
  });
}
