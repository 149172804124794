/** @jsx jsx */
import { jsx } from '@emotion/core';
import utils from '../../../utils';
import { SiteLink } from '../../../components';
import { tileStyle } from './productTileStyle';

export function ProductTile({ product }) {
  const s = tileStyle;
  const imageUrl = product.thumbnailUrl ? utils.site.resourcePath(product.thumbnailUrl) : '/assets/product-empty.png';
  //const imageUrl = "https://dummyimage.com/2560x800/333333/fff";
  //console.log("product : ", product)
  const productUrl = product.productUrl ? product.productUrl : `/product/${product.productSlug}`;
  return (
    <div css={[s.tile]} className="ecm__product__tile">
      <SiteLink css={s.tileLink} to={productUrl} className="ecm__product__tile__a">
        <div css={s.prdImage} className="ecm__product__tile__prd__img">
          <div className="ecm__product__tile__bg" css={s.bg} style={{ backgroundImage: utils.css.bgUrlStyle(imageUrl) }}></div>
        </div>
        <div css={s.desContainer} className="ecm__product__tile__des__container">
          
          <div css={s.name}>
            {product.productName}
          </div>
          
          <div css={s.shortDes}>
            {product.shortDesc}
          </div>

          <div css={s.price}>
          { 
            product.productStatus !== 0 ? product.productStatusText :
            product.priceTextOveriding ? product.priceTextOveriding : 'AU' + utils.money.formatMoney(product.priceIncTax)
          }
          </div>
          
        </div>
      </SiteLink>
    </div>
  )
}