/** @jsx jsx */
import { jsx } from "@emotion/core";
import style from "./orderDetailsStyle";
import React from "react";

import env from "../../../env";
import { useGet } from "../../../components";
import { Loading, ErrorPopup } from "../../../components";
import { useRouter } from "../../../components";
import utils from "../../../utils";
export function OrderDetails() {
  const get = useGet();
  const { query } = useRouter();
  const o = query.o || "";

  React.useEffect(() => {
    get.send(env.apiBase + "/api/order/getPaidOrder/" + o);
    document.querySelector("div.body-content").classList.add("product__page");
    // eslint-disable-next-line
  }, []);

  if (get.loading()) {
    return <Loading />;
  } else if (get.hasErrors()) {
    return <ErrorPopup title='Loading Error' errors={get.errors} />;
  }
  const order = get.response;
  return <Details order={order} />;
}

function Details({ order }) {
  console.log("order", order);
  const orderItems = order.orderItems.filter((x) => x.orderItemType === 0);
  const taxLines = order.orderItems.filter((x) => x.orderItemType === 1);

  function itemAmount(orderItem) {
    return (
      (orderItem.unitPrice * 100 +
        (orderItem.addedUnitPrice ? orderItem.addedUnitPrice * 100 : 0) -
        (orderItem.discountUnitPrice ? orderItem.discountUnitPrice * 100 : 0)) /
      100
    );
  }

  return (
    <div css={style.orderDetails}>
      <hr />
      <div className='row' style={{ marginLeft: "0px", marginRight: "0px" }}>
        <div className='col-md-12'>
          <div>
            <label css={style.fn}>Order Number:</label>{" "}
            <span css={style.fv}>{order.orderNo}</span>{" "}
          </div>
          <div>
            <label css={style.fn}>Total Amount:</label>{" "}
            <span css={style.fv}>
              <b>AU {utils.money.formatMoney(order.totalAmount)}</b>
            </span>{" "}
          </div>
          <div>
            <label css={style.fn}>Order Date:</label>{" "}
            <span css={style.fv}>
              {utils.date.formatDDMMYYYY(order.orderDate)}
            </span>{" "}
          </div>

          <div>
            <label css={style.fn}>
              {order.shippingMethod === "Standard"
                ? "Delivery Date:"
                : "Pickup Date:"}{" "}
            </label>
            <span css={style.fv}>{order.deliveryPickupDate}</span>
          </div>

          {order.shippingMethod === "Standard" && (
            <div>
              <label css={style.fn}>Delivery Time: </label>
              <span css={style.fv}>{order.deliveryPickupTime}</span>
            </div>
          )}

          {order.shippingMethod !== "Standard" && (
            <div>
              <label css={style.fn}>Pickup Location: </label>
              <span css={style.fv}>{order.location}</span>
            </div>
          )}

          <div>
            <label css={style.fn}>Payment Method:</label>{" "}
            <span css={style.fv}>{order.paymentMethod}</span>
          </div>
        </div>
      </div>

      <hr />

      <h3>ITEMS</h3>
      <div css={style.orderItems}>
        {orderItems.map((orderItem, i) => (
          <div
            css={style.orderItem}
            className='row'
            key={orderItem.orderItemId}
          >
            <div className='col-6'>
              <b dangerouslySetInnerHTML={{ __html: orderItem.itemName }}></b>
              <div
                dangerouslySetInnerHTML={{ __html: orderItem.itemDescHtml }}
              ></div>
            </div>
            <div className='col-3'>
              <div className='price'>
                Unit Price: {utils.money.formatMoney(itemAmount(orderItem))}
              </div>
            </div>
            <div className='col-3'>Qty: {orderItem.quantity}</div>
          </div>
        ))}

        <div css={style.orderItem} className='row'>
          <div className='col-9'>Shipping Fee:</div>
          <div className='col-3'>
            <div css={style.amount}>
              <b>{utils.money.formatMoney(order.shippingAmount)}</b>
            </div>
          </div>
        </div>

        {taxLines.map((orderItem, index) => (
          <div
            key={orderItem.orderItemId}
            css={style.orderItem}
            className='row'
          >
            <div className='col-9'>Tax: {orderItem.itemName}</div>
            <div className='col-3'>
              <div css={style.amount}>
                <b>{utils.money.formatMoney(orderItem.unitPrice)}</b>
              </div>
            </div>
          </div>
        ))}

        <div className='row' style={{ marginLeft: "0px", marginRight: "0px" }}>
          <div className='col-12'>
            <div css={style.total}>
              Total: AU {utils.money.formatMoney(order.totalAmount)}
            </div>
          </div>
        </div>
      </div>

      <hr />
      <h3>DELIVERY METHOD</h3>
      <div>{order.shippingMethod}</div>
      <Address addr={order.billingAddress} />
    </div>
  );
}

function Address({ addr }) {
  return (
    <div>
      <hr />
      <h3> CUSTOMER INFORMATION </h3>
      <div>
        <label css={style.fn}>Contact Name:</label>{" "}
        <span css={style.fv}>{addr.contactName}</span>{" "}
      </div>
      <div>
        <label css={style.fn}>Station / Business Name:</label>{" "}
        <span css={style.fv}>{addr.stationName}</span>{" "}
      </div>
      <div>
        <label css={style.fn}>Department:</label>{" "}
        <span css={style.fv}>{addr.department}</span>{" "}
      </div>
      <div>
        <label css={style.fn}>Email Address:</label>{" "}
        <span css={style.fv}>{addr.email}</span>{" "}
      </div>
      <div>
        <label css={style.fn}>Contact Number:</label>{" "}
        <span css={style.fv}>{addr.mobile}</span>{" "}
      </div>
    </div>
  );
}
