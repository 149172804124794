//import { css } from '@emotion/core';
import variables from '../../../cssInJs/variables';
import { mq } from '../../../cssInJs';
export default {
  desc: {
    'p': {
      fontSize: '1rem !important',
      fontFamily: variables.familyBase + ' !important',
      marginBottom: '0.5rem !important',
      lineHeight: '1.1 !important'
    }
  },
  heroImageContainer: mq({
    //textAlign: 'center',
    height: ['180px', '340px'],
    position: 'relative',
  }),
  heroImage: mq({
    //maxWidth: '350px !important',
    //height: '100% !important'
    maxHeight: ['180px', '340px'],
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  }),
  productPageCol: mq({
    paddingLeft: '45px',
    paddingRight: '45px'
  }),
  productFavorite: {
    cursor: 'pointer',
    '&.checked': {
      color: 'red'
    }
  },
  productBreadCrumbs: mq({
    padding: '0px',
    listStyle: 'none',
    marginBottom: '0.5rem',
    display: 'block',
    'li': mq({
      display: 'inline-block',
      marginRight: '1.5rem',
      position: 'relative',
      fontSize: '0.7rem',
      '&:not(:last-child)::after, &.breadCrumb.last-item::after': {
        content: '">"',
        position: 'absolute',
        right: '-1rem'
      },
      '&.breadCrumb::after': {
        content: '"|"',
        position: 'absolute',
        right: '-1rem'
      }
    })
  }),
  productViewImageBg: mq({
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    paddingTop: ['80px', '120px'],
    backgroundRepeat: 'no-repeat',
    marginLeft: '5px',
    marginRight: '5px'
  }),
  productViewImages: mq({
    marginTop: ['1rem', '2rem'],
    paddingBottom: ['1rem', '2rem'],
    marginLeft: '-5px',
    marginRight: '-5px',
    '.slick-prev, .slick-next': {
      transform: 'none',
      top: '150px',
      '&:before': {
        color: '#999999'
      }
    },
    '.slick-prev': {
      left: '150px'
    },
    '.slick-next': {
      right: '150px'
    }
  }),

  productViewImage: {
    //paddingLeft: '5px',
    //paddingRight: '5px'
    outline: 'none',
    cursor: 'pointer'
  },

  productViewShortDes: mq({
    fontSize: ['1rem'],
    textAlign: 'center',
  }),

  productViewTitle: mq({
    fontSize: ['2rem'],
    textAlign: 'left',
    marginTop: '0px',
    marginBottom: '1rem'
  }),

  addToCartBtn: {
    //width: '100%'
    'button, .btn': {
      width: '100%'
      //transform: 'skewX(-12deg)'
    },
    // 'button.gotocartbtn' : {
    //   marginTop: '1rem'
    // }
  },
  priceText: {
    fontSize: '0.8rem'
  },
  socialMedia: {
    '.social-icon-text': {
      fontSize: '0.8rem'
    }
  },
  attributesRow: {
    cursor: 'pointer',
    //marginTop: '0.5rem',
    //marginBottom: '0.5rem',
    paddingTop: '0.2rem',
    paddingBottom: '0.2rem',
    '&:hover': {
      backgroundColor: '#999999',
      color: 'white'
    },
    '.svgContainer svg': {
      transition: 'all 0.5s'
    },
    '.svgContainer.open svg': {
      transform: 'rotate(180deg)'
    }
  },

  attrContainer: {
    maxHeight: '0px',
    transition: 'all 0.3s',
    overflow: 'hidden',
    '&.open': {
      maxHeight: '1000px'
    }
  },

  desktopMobile: {
    '&.mobile': mq({
      display: ['block', 'none']
    }),
    '&.desk__top': mq({
      display: ['none', 'block']
    })
  },
  quoteContainer: {
    marginBottom: '1rem',
    '.quote__text': {
      fontSize: '0.8rem',
      marginBottom: '1rem'
    },
    button: {
      //transform: 'skewX(-12deg)'
    }
  },
  enquiryFormContainer: {
    backgroundColor: 'rgba(0,0,0,0.3)',
    position: 'fixed',
    top: '0px',
    left: '0px',
    width: '100%',
    height: '100%',
    zIndex: '9999',
    'form.contactForm': {
      position: 'absolute',
      width: '100%',
      maxWidth: '900px',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: 'grey',
      //paddingTop: '2rem',
      paddingLeft: '1rem',
      paddingRight: '1rem',
      margin: '0px',
      '.closeButton': {
        textAlign: 'right',
        //margin: '1rem 0px',
        cursor: 'pointer',
        color: 'white',
        marginTop: '0.5rem'
      },
      '.enquireNowText': {
        marginBottom: '0.5rem',
        color: 'white'
      }
    }
  },

  productView__relatedProducts: {
    'h3': {
      textAlign: 'center'
    },
    '.productList': mq({
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
      maxWidth: ['980px'],
      margin: 'auto',
      marginLeft: ['-10px', null, 'auto'],
      marginRight: ['-10px', null, 'auto'],
    }),
    '.ecm__product__tile': mq({
      maxWidth: ['100%', null, '33.3%'],
      flexBasis: ['100%', null, '33.3%'],
    }),
    '.ecm__product__tile__a': {
      flexWrap: 'wrap'
    },
    '.ecm__product__tile__prd__img': mq({
      flexBasis: ['40%', null, '100%'],
      maxWidth: ['40%', null, '100%'],
    }),
    '.ecm__product__tile__des__container': mq({
      paddingTop: ['0px',null,'0.5rem'],
      paddingLeft: ['0.8rem',null,'0.5rem'],
      flexBasis: ['60%', null, '100%'],
      maxWidth: ['60%', null, '100%'],
    }),
    '.ecm__product__tile__bg': mq({
      paddingTop: ['110px',null,'56.25%']
    })
  }
}