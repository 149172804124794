/** @jsx jsx */
import { jsx } from '@emotion/core';
import { getBusinessFormModel } from './businessFormModel';
import { useForm } from '../../../../form';
import { usePost, Button, ErrorMessage } from '../../../../components';
import React from 'react';
import env from '../../../../env';

export function BusinessFormItem(props){
    const emailReceiver = props.emailReceiver;
    //const fileAttachment = props.fileAttachment || 'false';
    const mailchimpApiKey = props.mailchimpApiKey;
    const mailchimpAudienceKey = props.mailchimpAudienceKey;

    const model = getBusinessFormModel({});
    const form = useForm(model, { usePlaceholder: true });

    const [sendingStatus, setSendingStatus] = React.useState(null);
    const [mailChimpError, setMailChimpError] = React.useState();
    const post = usePost();
    const s = props.s;

    const mailChimpErrorMemo = React.useMemo(() => {
        return mailChimpError
    }, [mailChimpError]);

    function onSubmit(e) {
        setMailChimpError(null);
        form.validateForm(e, () => {
          const value = { 
            ...form.getValue(),
             emailReceiver: emailReceiver,
             mailchimpApiKey : mailchimpApiKey,
             mailchimpAudienceKey : mailchimpAudienceKey
          };
          var files = form.getFiles();
          var formData = new FormData();
          formData.append('jsonData', JSON.stringify(value))
          files.forEach(file => {
            (file.files || []).forEach(uploadFile => {
              formData.append(file.name, uploadFile)
            })
          });
          //post.send(env.apiBase + "/api/contact/send", value);
          post.sendFormData(env.apiBase + "/api/contact/sendbusinessform", formData);
          setSendingStatus('pending');
        })
        console.log('submitting');
    }

    if (post.done() && sendingStatus === 'pending') {
        //var value = form.getValue();
        //gtm.sendEvent('contactFormSubmitted', {contactEmail: value.email} )
        form.reset(model);
        setSendingStatus(null);
    
        if(post.response.results && (post.response.results.MailChimpError && post.response.results.MailChimpError !== "")){
          setMailChimpError("Mail Chimp Error : " + post.response.results.MailChimpError);
        }
    }

    const render = (name) => form.renderControl(name, null);

    return(
        <form className="contactForm" css={s.formStyle}>
            <div className="form-row">
                <div className="col-md-6 mb-2">{render('businessName')}</div>
                <div className="col-md-6 mb-2">{render('ownerName')}</div>
            </div>
            <div className="form-row">
                <div className="col-md-12 mb-2">{render('address')}</div>
            </div>
            <div className="form-row">
                <div className="col-md-6 mb-2">{render('email')}</div>
                <div className="col-md-6 mb-2">{render('phone')}</div>
            </div>
            <div className="form-row">
                <div className="col-md-12 mb-2">{render('checkBoxAccount')}</div>
            </div>
            <div className="form-row">
                <div className="col-md-12 mb-2">
                    <div className="actions">
                        <Button onClick={onSubmit} status={post.status}>Send Message</Button>
                        <ErrorMessage errors={form.errors} summaryMessage="Please review the errors." />
                        <ErrorMessage errors={post.errors} />
                        <ErrorMessage errors={mailChimpErrorMemo} />
                        {post.done() && <span style={{marginLeft: '1.5rem', color:'red', fontWeight: 'bold'}}>Successfully sent!</span>}
                    </div>
                </div>
            </div>
        </form>
    )

}