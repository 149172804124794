import { fb, validators } from '../../../../lib/form';

export function getBusinessFormModel(data) {
    const model = fb.group({
      businessName: [data.businessName || '', [validators.Required()], { label: 'Business Name', type: 'text' }],
      ownerName: [data.ownerName || '', [validators.Required()], { label: 'Owner Name', type: 'text' }],
      address: [data.address || '', [validators.Required()], { label: 'Address', type: 'text' }],
      email: [data.email || '', [validators.Required(), validators.Email()], { label: 'Email', type: 'email' }],
      phone: [data.phone || '', [validators.Required()], { label: 'Phone', type: 'text' }],
      checkBoxAccount: [data.checkBoxAccount || false, [], { label: 'I am interested in creating a wholesale account with Vili’s', type: 'checkbox' }],
    }); 
    return model;
  }