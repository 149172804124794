import { fb, validators } from '../../../lib/form';

export function getContactModel(data) {
  const model = fb.group({
    name: [data.name || '', [validators.Required()], { label: 'Name', type: 'text' }],
    phone: [data.phone || '', [validators.Required()], { label: 'Phone', type: 'text' }],
    state: [data.state || '', [validators.Required()], { label: 'Postcode', type: 'number' }],
    email: [data.email || '', [validators.Required(), validators.Email()], { label: 'Email', type: 'email' }],
    enquiry: [data.enquiry || '', [validators.Required()], { label: 'Your Message', type: 'textarea' }],
    attachments: [data.attachments || '', [], { label: 'Attachments', type: 'file' }]
  }); 
  return model;
}