/** @jsx jsx */
import { jsx } from '@emotion/core';
import utils from '../../../../utils';
import cmsUtils from '../../cmsUtils';
import { BusinessFormItem } from './businessFormItem';
import { getBusinessFormStyle } from './businessFormStyle';

export function BusinessForm(props){

    const item = props.item;
    
    const cmsOption = props.cmsOption || {};
    const cssClass = utils.classNames('cms_item', 'business-form', item.cssClass || item.anchorName || '');

    const emailReceiver = cmsUtils.payload(item, 'EmailReceiver');
    const fileAttachment = cmsUtils.payload(item, 'FileAttachment') || "false";

    const mailchimpApiKey = cmsUtils.payload(item, 'MailchimpApiKey');
    const mailchimpAudienceKey = cmsUtils.payload(item, 'MailchimpAudienceKey');

    const s = getBusinessFormStyle();

    return(
        <div className={cssClass} data-cms-html="true"
            data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName}>
            <div className="container business-form-wrapper" id="contactForm">
                <BusinessFormItem s = {s} emailReceiver={emailReceiver} fileAttachment = {fileAttachment} mailchimpApiKey = {mailchimpApiKey} mailchimpAudienceKey = {mailchimpAudienceKey} />
            </div>
        </div>
    )
}